/**
 * @description 合同常量表
 */


//税费合同类型
export const TAXATION_TYPES = [
    {
        name: "增值税",
        type: 0
    }, {
        name: "印花税",
        type: 1
    },
    {
        name: "企业所得税",
        type: 2
    },
    {
        name: "个人所得税",
        type: 3
    }, {
        name: "垃圾费",
        type: 4
    }
]

//人工合同
export const MANMADE_TYPES=[
    {
        name: "工资",
        type: 5
    },
    {
        name: "社保",
        type: 6
    }, {
        name: "公积金",
        type: 7
    }
]


//办公费合同
export const OFFICAL_TYPES=[
    {
        name:"用品采购",
        type:1
    
    },{
        name:"房租费用",
        type:2
    },{
        name:"物业费",
        type:3
    },{
        name:"水电费",
        type:4
    },{
        name:"手续费",
        type:5
    }
]

//客户类型
export const CUSTOMER_TYPES = [
    {
        name: "客户",
        type: 0
    }, {
        name: "供应商",
        type: 1
    }
]


//合同完成状态
export const CONTRACT_STATUS = [
    {
        name: "待完成",
        type: 0
    }, {
        name: "已完成",
        type: 1
    }
]


