
<template>
    <div class="contract-section">
        <custom-table :loadData="loadData" :remove="remove" :drawers="drawers" :edit="edit" :pageInfo="pageInfo"
            :statistical="statistical" :filters="filters" :tableColumns="tableColumns" />
    </div>
</template>

<script>
import { MANMADE_TYPES } from "@/constant/contract"
import * as moment from "moment"
import { list, add, remove, update, getTotalMoney } from "@/api/taxlabor.js"

export default {
    data() {
        return {
            filters: [
                {
                    name: "项目名称",
                    value: "",
                    property: "projectName",
                    defaultValue() {
                        return ''
                    },
                    type: "input",
                    clearable: true
                }, {
                    name: "付款日期",
                    property: 'paymentDate',
                    value: null,
                    type: 'date',
                    
                    defaultValue() {
                        return null
                    },
                    format(f) {
                        if (f) {
                            return moment(f).format("YYYY-MM-DD")
                        } else {
                            return null
                        }
                    },
                    clearable: true
                }
                , {
                    name: "类型",
                    property: "type",
                    value: 5,
                    doSearch:true,
                    defaultValue() {
                        return 5
                    },
                    type: 'select',
                    clearable: false,
                    options: MANMADE_TYPES
                }
            ],
            drawers: [
                {
                    name: "项目名称",
                    value: "",
                    property: "projectName",
                    defaultValue() {
                        return ''
                    },
                    type: "input",
                    require: true,
                    clearable: true
                }, {
                    name: "付款金额",
                    property: "paymentAmount",
                    value: 0,
                    type: 'number',
                    min: 0,
                    require: true,
                    defaultValue() {
                        return 0
                    },
                    formatCommit(e) {
                        return e.toFixed(2)
                    },
                    clearable: true
                }, {
                    name: "付款周期",
                    property: "cycle",
                    value: null,
                    type: 'date-rand',
                    require: true,
                    defaultValue() {
                        return null
                    },
                    formatDrawer: (date) => {
                        if (date) {
                            return [new Date(date.split("~")[0]), new Date(date.split("~")[1])]
                        }
                        return null
                    },
                    formatCommit: (date) => {
                        return moment(new Date(date[0])).format("YYYY-MM-DD") + '~' + moment(new Date(date[1])).format("YYYY-MM-DD")
                    },

                    clearable: true
                }, {
                    name: "付款日期",
                    property: "paymentDate",
                    value: null,
                    type: 'date',
                    require: true,
                    defaultValue() {
                        return null
                    },
                    clearable: true
                }, {
                    name: "类型",
                    value: 5,
                    property: 'type',
                    require: true,
                    defaultValue() {
                        return 5
                    },
                    type: 'select',
                    clearable: false,
                    options: MANMADE_TYPES
                }
                , {
                    name: "银行回单附件pdf",
                    value: '',
                    property: "url",
                    require: false,
                    defaultValue() {
                        return ''
                    },

                    type: 'file',
                    clearable: true,
                    fileTypes: ".pdf"
                }
               
            ],
            tableColumns: [
                {
                    name: "项目名称",
                    props: "projectName",
                },
                {
                    name: "付款金额",
                    props: "paymentAmount",
                    isFormat: true,
                    format: (price) => {
                        return "￥" + price
                    }
                }, {
                    name: "付款日期",
                    props: "paymentDate",
                    isFormat: true,
                    format: (date) => {
                        return moment(new Date(date)).format("YYYY-MM-DD")
                    },
                    formatCommit: (date) => {
                        return moment(new Date(date)).format("YYYY-MM-DD")
                    }
                }, {
                    name: "所属周期",
                    props: "cycle",
                    isFormat: true,
                    format: (date) => {
                        if (date) {
                            date = date.split("~")
                            return moment(new Date(date[0])).format("YYYY-MM-DD") + '~' + moment(new Date(date[1])).format("YYYY-MM-DD")
                        }
                        return null
                    },

                }, {
                    name: "银行回单附件pdf",
                    url: 'url',
                    isPdf: true
                },
                {
                    name: "操作",
                    isEdit: true,
                    edits: ['remove', 'edit']
                }
            ],
            pageInfo: {
                pageSize: 10,
                pageNumber: 1,
                total: 1000
            },
            totalData: {

            }
        }
    },
    computed: {
        statistical() {

            let data = [
                {
                    name: "人工总额",
                    value: this.totalData.costLabor || 0
                },
                {
                    name: "工资",
                    value: this.totalData.salary || 0
                },
                {
                    name: "社保",
                    value: this.totalData.socialSecurity || 0
                },
                {
                    name: "公积金",
                    value: this.totalData.accumulationFund || 0
                }
            ]
            return data
        },

    },
    methods: {
        async loadData(data) {
            getTotalMoney().then(({ data }) => {
                this.totalData = data
            })

            let lists=[]
            let total=0
            try{
            let res = await list(data)
                lists=res.data.list
                total=res.data.total
            }catch(error){
                console.log(error)
                
            }
            return {
                list:lists,
                total
            }

        },
        async remove(row) {
            await remove({
                id: row.id
            })
        },
        async edit(data) {
            let httpMethod = data.drawerType == 'edit' ? update : add
            delete data['drawerType']
            await httpMethod(data)
        },

    }


}
</script>